/* eslint-disable camelcase */

import dark from './dark.jpg';
import eco from './eco.jpg';
import express from './express.jpg';
import landscape from './landscape.jpg';
import leafdrops from './leafdrops.jpg';
import raindrops from './raindrops.jpg';
import sunrise from './sunrise.jpg';
import tent from './tent.jpg';
import forest1 from './forest1.jpg';
import tilenga from './tilenga.jpg';
import giraffe from './giraffe.jpg';
import heli from './heli.jpg';
import housing from './housing.jpg';
import juba from './juba.jpg';
import oil from './oil.jpg';
import transmissionLine from './transmissionLine.jpg';


export default {
  dark,
  eco,
  express,
  landscape,
  leafdrops,
  raindrops,
  sunrise,
  tent,
  forest1,
  tilenga,
  giraffe,
  heli,
  housing,
  juba,
  oil,
  transmissionLine,
};
